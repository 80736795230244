<template>
  <div class="home">
    <div class="header_box">
      <div class="show">
        <div class="img"></div>
        <div class="from" v-if="!$store.state.app.token || $store.state.app.userInfo == null">
          <router-link to="/login" class="i">登录</router-link>
          <span class="sp">|</span>
          <a class="i" @click="toRegister()">注册</a>
        </div>
        <div class="from" v-if="$store.state.app.token && $store.state.app.userInfo != null">
          <div class="user-info">
            {{ $store.state.app.userInfo.account }}
            <ul class="ssp-s">
              <li><router-link to="/user">个人中心</router-link></li>
              <li @click="logout()">退出登录</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="cen">
      <div class="cen_box">
        <div class="cen-tibia">
          <div class="tia" v-for="(item, index) in navs" :key="index"
            :class="{ active: $route.matched[0].path == item.link_url }">
            <div class="tia_img" :style="{
              backgroundImage: 'url(' + item.copy + ')',
            }"></div>
            <router-link :to="item.link_url" class="tias">{{
              item.name
              }}</router-link>
            <div v-if="item.children.length > 0" class="tia-ti">
              <div v-for="(items, indexs) in item.children" :key="indexs" class="tia-ti-item" :class="{
                active: $route.matched[0].path == item.children.link_url,
              }">
                <router-link :to="items.link_url" class="h">{{
                  items.name
                  }}</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="cen-but">
        <swiper class="swiper-container" :options="swiperOption" ref="mySwiper">
          <swiper-slide class="swiper-slide" v-for="(item, index) in carouselsByCid" :key="index">
            <router-link :to="item.link_url"><img :src="item.copy" alt="" /></router-link>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
          <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div>
        </swiper>
      </div>
    </div>

    <div class="chose-register">
      <div class="chose-register-title">
        <h1>请选择您要注册的账号类型</h1>
      </div>
      <div class="chose-register-content clearfix">
        <div class="left">
          <img src="@assets/images/person.png" alt="" />
          <div class="goRegister">
            <a href="/register">去注册个人账号</a>
          </div>
        </div>
        <div class="right">
          <img src="@assets/images/persons.png" alt="" />
          <div class="goRegister">
            <a href="/unitRegister">去注册单位账号</a>
          </div>
        </div>
      </div>
    </div>
    <foot></foot>
  </div>
</template>

<script lang="">
import "@css/foot.css";
import $ from "jquery";
import $store from "../store";
import "swiper/dist/css/swiper.css";
import foot from "@components/foot.vue";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import { getNavList, getCarouselList } from "@api/public";
import { getUserInfo, logout } from "@api/user";
export default {
  name: "Home",
  components: {
    foot,
    swiper,
    swiperSlide,
  },
  props: {},
  data: function () {
    return {
      navs: [],
      carouselsByCid: [],
      userInfo: {},
      swiperOption: {
        init: false,
        loop: true,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        observer: true,
        observeParents: true,
      },
    };
  },
  computed: {
    swiper: function () {
      return this.$refs.mySwiper.swiper;
    },
  },
  mounted: function () {
    let that = this;
    window.onresize = function () {
      return (function () {
        that.$router.go(0);
      })();
    };
    getNavList({ nav_category_id: 2 }).then(function (res) {
      that.$set(that, "navs", res.data);
    });
    getCarouselList({ cid: 1 }).then(function (res) {
      that.$set(that, "carouselsByCid", res.data.list);
    });
    if ($store.state.app.token) {
      // getUserInfo()
      //   .then((res) => {
      //     that.$set(that, "userInfo", res.data);
      //   })
      //   .catch(function (e) {});
    }
    // console.log(this.startTime.toString().length);
  },
  created: function () {
    document.title = this.$route.meta.title;
    if ($store.state.app.userInfo == null || !$store.state.app.token) {
      this.$store.commit("LOGOUT");
    }
  },

  methods: {
    logout: function () {
      let that = this;
      logout().then(function (res) {
        if (res.code == 200) {
          that.$store.commit("LOGOUT");
          location.href = location.origin;
        }
      });
    },
    toRegister: function () {
      layui.use(["layer"], function () {
        let layer = layui.layer;
        layer.open({
          title: false,
          type: 1,
          closeBtn: 0,
          anim: 0,
          area: ["40%", "48%"],
          shadeClose: true,
          content: $(".chose-register"),
          skin: "layui-layer-lan",
          end: function () {
            $(".chose-register").hide();
          },
        });
      });
    },
  },
  updated: function () {
    if (this.carouselsByCid.length > 1) {
      this.swiper.init();
    }
  },
  destroyed: function () {
    $(".layui-layer-shade").remove();
  },
};
</script>

<style scoped>
.header_box {
  width: 100%;
  min-width: 1484px;
  height: 60px;
  background: #beb9ae;
}

.h:hover {
  color: #72210a;
}

.show {
  display: block;
  width: 1484px;
  height: 60px;
  margin: 0 auto;
  position: relative;
}

.img {
  width: 737px;
  height: 160px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background-image: url(../assets/images/head.png);
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center 0;
}

.img-top {
  width: 342px;
  height: 20px;
  margin-top: 60px;
  margin-left: 192px;
  font-size: 14px;
  text-align: center;
  color: #fff;
  letter-spacing: 2px;
}

.img-but {
  width: 547px;
  height: 40px;
  margin: 10px 0 0 90px;
  color: #fadeb4;
  font-size: 30px;
  letter-spacing: 4px;
  text-align: center;
}

span {
  font-size: 14px;
  color: #607a5d;
}

.sp {
  margin: 0 10px;
}

.cen {
  width: 100%;
  height: 1032px;
  min-width: 1484px;
  background-image: url(../assets/images/back.jpg);
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center 0;
}

.cen_box {
  position: relative;
  width: 1484px;
  height: auto;
  margin: 0 auto;
}

.i {
  text-decoration: none;
  color: #607a5d;
  cursor: pointer;
}

.cen>.cen-but {
  width: 1482px;
  height: 492px;
  position: absolute;
  top: 480px;
  left: 50%;
  margin-left: -741px;
}

.cen-tibia {
  width: 1150px;
  height: 50px;
  padding-top: 130px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.tia_img {
  width: 50px;
  height: 50px;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center 0;
}

.tia {
  width: 50px;
  height: 192px;
  position: relative;
}

.tias {
  width: 20px;
  height: 120px;
  font-size: 24px;
  margin: 20px 15px 0;
  font-weight: 600;
  color: #000;
  text-decoration: none;
  display: block;
  font-family: "楷体";
}

.tians {
  width: 20px;
  height: 120px;
  font-size: 16px;
  margin: 72px 15px 0;
  color: #000;
  text-decoration: none;
  display: block;
}

.active:hover .tia-ti {
  display: block;
}

.tia-ti {
  width: 150px;
  font-size: 14px;
  position: absolute;
  top: 150px;
  left: 20px;
  display: none;
  padding-left: 30px;
  font-family: "华文细黑";
  z-index: 100;
}

.tia-ti-item {
  letter-spacing: 2px;
  color: #5f7a5d;
  display: block;
  margin-top: 10px;
}

.h {
  text-decoration: none;
  letter-spacing: 2px;
  color: #5f7a5d;
}

.tia-ti-items {
  margin: 13px 0;
  letter-spacing: 2px;
  color: #5f7a5d;
  text-decoration: none;
  display: block;
}

.cen-but {
  width: 1482px;
  height: 492px;
  position: absolute;
  top: 426px;
  left: 215px;
  border-radius: 5px;
  padding-top: 50px;
}

.cen-but img {
  width: 100%;
  height: 100%;
}

.butt {
  width: 265px;
  height: 25px;
  display: flex;
  border-radius: 15px;
  position: absolute;
  top: 68px;
  left: 24px;
  z-index: 1000;
  min-width: 1.38021rem;
  min-height: 0.13021rem;
}

.bto {
  width: 60px;
  height: 27px;
  min-width: 0.3125rem;
  border: none;
  outline: none;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  background: #fff;
  color: #9e3012;
}

.ipt {
  width: 240px;
  height: 27px;
  border: none;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  outline: none;
}

.wh_slide img {
  border-radius: 10px;
}

.from {
  position: relative;
  top: 24px;
  font-size: 14px;
  right: 10px;
  float: right;
  height: 100%;
  cursor: pointer;
}

.user-info {
  position: relative;
  height: 100%;
  color: #607a5d;
}

.from:hover .ssp-s {
  display: block;
  cursor: pointer;
}

.ssp-s {
  display: none;
  position: absolute;
  left: -5px;
  top: 25px;
  min-width: 100%;
  line-height: 36px;
  padding-top: 11px;
  background-color: transparent;
  z-index: 100;
  border-radius: 2px;
  white-space: nowrap;
  cursor: pointer;
}

.ssp-s li {
  position: relative;
  color: #607a5d;
  background-color: #beb9ae;
  line-height: 40px;
  height: 40px;
  text-align: center;
  padding: 0px 10px;
  /* margin-top: 10px; */
  cursor: pointer;
}

.swiper-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  position: relative;
  height: 100%;
  width: 100%;
}

.chose-register {
  /* border: 1px solid blue; */
  display: none;
  padding: 10px 20px;
}

.chose-register .chose-register-title {
  position: relative;
  margin: 30px auto;
  font-family: HYQuanTangShiJ;
  text-align: center;
}

.chose-register .chose-register-title h1 {
  position: relative;
  padding: 120px auto;
  display: block;
  font-size: 32px;
}

.chose-register .chose-register-content {
  position: relative;
  padding: auto 50px;
}

.chose-register .chose-register-content div {
  position: relative;
}

.chose-register .chose-register-content div.left {
  text-align: center;
  float: left;
  width: 45%;
}

.chose-register .chose-register-content div img {
  width: 220px;
  height: auto;
}

.chose-register .chose-register-content div.right {
  float: right;
  width: 45%;
  text-align: center;
}

.chose-register .chose-register-content .goRegister {
  position: relative;
  margin-top: 30px;
  font-size: 22px;
  font-family: HYQuanTangShiJ;
}

.chose-register .chose-register-content .goRegister a {
  display: inline-block;
  background: #c52d3b;
  padding: 20px 25px;
  border-radius: 10px;
  color: #fff;
}
</style>
